<template>
  <div class="pt-10">
    <v-card class="my-campaigns-card" style="position: relative">
      <covered-loader v-if="isGettingCampaigns" />
      <div>
        <v-chip
          v-if="isHaveError"
          style="width: 100%"
          class="mb-2 pa-5 text-center justify-center"
          color="orange"
          label
          outlined
        >
          {{ warningText }}
        </v-chip>
      </div>
      <v-card-title class="pa-0 ma-0">
        <h3 class="font-family-raleway-bold text-uppercase">
          {{ $t("my_campaigns_page.title") }}
        </h3>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="clickNewCampaignButton"
              class="create-btn button"
              height="50"
              v-on="on"
            >
              <v-icon class="icon">mdi-plus-circle</v-icon>
              <span
                style="font-size: 14px"
                class="ml-2 font-family-raleway-medium"
                >{{ $t("my_campaigns_page.create_new_button") }}</span
              >
            </v-btn>
            <v-btn
              @click="clickNewCampaignButton"
              class="create-btn-mobile button"
              height="50"
              v-on="on"
            >
              <v-icon class="icon">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("my_campaigns_page.create_new_button") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-title class="pa-0 ma-0 mt-5 d-flex align-center fd-col">
        <v-text-field
          :label="$t('my_campaigns_page.search_by_name')"
          outlined
          dense
          style="min-width: 300px"
          @input="changeSearchText"
          class="font-family-raleway-medium width-100"
          :class="getBreakPoint ? '' : 'pr-16'"
        >
          <template v-slot:prepend-inner>
            <v-icon>mdi-magnify</v-icon>
          </template>
        </v-text-field>
        <v-spacer class="ds-none"></v-spacer>
        <div
          class="align-end d-flex fd-col width-100 align-center"
          style="align-items: center"
        >
          <span
            v-if="!getBreakPoint"
            style="font-size: 14px"
            class="font-family-raleway-medium pb-5"
            >{{ $t("my_campaigns_page.statistics_filter") }}</span
          >
          <v-select
            style="width: 300px"
            :items="itemsSelects"
            v-model="filterType"
            @input="changeFilter"
            item-value="value"
            item-text="label"
            class="font-family-raleway-medium width-100"
            :class="getBreakPoint ? '' : 'pl-16'"
            :label="$t('my_campaigns_page.filter_by')"
            outlined
            dense
            flat
          >
            <template v-slot:item="{ item }">
              <div>{{ item.label }}</div>
            </template>
          </v-select>
          <div
            class="d-flex align-center ml-2 width-100 fd-col-calendar"
            style="gap: 5px"
            v-if="filterType === 13"
          >
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
              class="color-blue"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Start Date"
                  persistent-hint
                  class="font-family-raleway-medium width-100"
                  prepend-inner-icon="mdi-calendar"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              ref="menu1"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
              class="color-blue"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="font-family-raleway-medium width-100"
                  v-model="endDate"
                  label="End Date"
                  persistent-hint
                  prepend-inner-icon="mdi-calendar"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="pb-4 pl-4 columns-div">
            <columns-component @addColumns="clickApplyColumns" />
          </div>
        </div>
      </v-card-title>
      <v-data-table
        :items="conDesserts"
        class="elevation-1 table"
        hide-default-footer
      >
        <template v-slot:header>
          <thead class="thead">
            <tr
              class="font-family-raleway-medium color-gray"
              style="border-bottom: 1px solid black !important"
            >
              <td
                style="font-size: 15px; cursor: pointer"
                v-for="item of headers"
                :key="item.value"
              >
                <div class="d-flex align-center" @click="handleClickSort(item)">
                  <img
                    :src="sortIcons"
                    v-if="
                      item.isSorting &&
                      sort === 'ASC' &&
                      item.value !== 'status' &&
                      item.value !== 'actions'
                    "
                  />
                  <img
                    v-else-if="
                      item.value !== 'status' && item.value !== 'actions'
                    "
                    :src="sortIcons"
                    style="transform: rotateX(180deg)"
                  />
                  {{ $t(item.text) }}
                </div>
              </td>
            </tr>
          </thead>
        </template>
        <template v-slot:body="props">
          <tbody>
            <tr
              v-for="(item, index) in props.items"
              :key="index"
              class="table-row tbody"
            >
              <td class="tbody-td campaign-name-title" style="font-size: 16px">
                <span
                  class="color-blue font-family-raleway-medium"
                  @click="handleClickDetails($event, item.id)"
                >
                  {{ item.name }}
                </span>
                <div
                  style="font-variant-numeric: lining-nums; font-size: 14px"
                  class="color-gray"
                >
                  {{ numberWithSpaces(item.campaignId) }} -
                  {{ $t(GETCAMPAIGN_STATUS[item.status]) }}
                </div>
              </td>
              <td class="font-family-raleway-medium" style="font-size: 16px">
                {{ item.budgetI }}
              </td>
              <td class="font-family-raleway-medium" style="font-size: 16px">
                {{ item.impressions }}
              </td>
              <td class="font-family-raleway-medium" style="font-size: 16px">
                {{ item.clicks }}
              </td>
              <td class="font-family-raleway-medium" style="font-size: 16px">
                {{ item.ctr }}
              </td>
              <td class="font-family-raleway-bold" style="font-size: 16px">
                {{ item.cpc }}
              </td>
              <td class="font-family-raleway-bold" style="font-size: 16px">
                {{ item.cost }}
              </td>
              <td
                class="font-family-raleway-bold"
                style="font-size: 16px"
                v-if="isHaveItemHeader('conversions')"
              >
                {{ item.conversions }}
              </td>
              <td
                class="font-family-raleway-bold"
                style="font-size: 16px"
                v-if="isHaveItemHeader('costCon')"
              >
                {{ item.costCon }}
              </td>
              <td
                class="font-family-raleway-bold"
                style="font-size: 16px"
                v-if="isHaveItemHeader('conRate')"
              >
                {{ item.conRate }}
              </td>
              <td
                class="font-family-raleway-bold"
                style="font-size: 16px"
                v-if="isHaveItemHeader('conValue')"
              >
                {{ item.conValue }}
              </td>
              <td
                class="font-family-raleway-bold"
                style="font-size: 16px"
                v-if="isHaveItemHeader('conValueCost')"
              >
                {{ item.conValueCost }}
              </td>
              <td class="font-family-raleway-medium">
                <div class="d-flex align-center">
                  <div
                    class="d-flex align-center px-3 py-2"
                    :class="
                      GETCAMPAIGN_STATUS[item.status] === 'active_chip'
                        ? 'Active'
                        : 'Paused'
                    "
                    style="height: 32px; border-radius: 10px"
                  >
                    <img
                      :src="activeIcon"
                      v-if="GETCAMPAIGN_STATUS[item.status] === 'active_chip'"
                      class="mr-1"
                      width="14"
                      height="14"
                    />
                    <v-icon
                      v-if="GETCAMPAIGN_STATUS[item.status] === 'paused_chip'"
                      color="#F2994A"
                      small
                    >
                      mdi-pause
                    </v-icon>
                    <span
                      class="ml-1 font-family-raleway-bold text-uppercase"
                      style="font-size: 14px"
                    >
                      {{ $t(GETCAMPAIGN_STATUS[item.status]) }}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex align-center">
                  <div style="cursor: pointer">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="pauseAvtivate(item)"
                          :disabled="item.status === 5"
                          color="warning"
                          v-on="on"
                          large
                          icon
                        >
                          <v-icon color="warning" class="px-2">
                            mdi-play-pause
                          </v-icon>
                        </v-btn>
                      </template>
                      <span
                        >{{ item.status === 1 ? $t("pause") : $t("active") }}
                        {{ $t("campaign") }}!</span
                      >
                    </v-tooltip>
                  </div>
                  <div class="ml-2" style="cursor: pointer" disabled>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="isActive(item)"
                          :disabled="item.status === 5"
                          style="background-color: #fddada"
                          class="box-shadow-none border-16"
                          v-on="on"
                          height="48"
                          width="48"
                          small
                        >
                          <v-icon color="red">mdi-delete-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("delete_campaign") }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <h4
        v-if="conDesserts.length === 0"
        class="my-8 text-center font-weight-regular"
      >
        <span v-if="loading">{{ $t("loading") }}...</span>
        <span v-else>{{ $t("no_campaign") }}</span>
      </h4>
      <v-divider class="mb-6" />
      <pagination
        v-if="campains.length > 8"
        :currentPage="currentPage"
        :totalPage="totalPage"
        @handleUpdateCampaignList="handleUpdateCampaignList"
        @prePage="prePage"
        @nextPage="nextPage"
        @changePage="changePage($event)"
      />
      <DeleteModal
        :isdelete="isdelete"
        :dialog="dialog"
        :dialogItem="dialogItem"
        @functionAgree="handleDelete($event)"
        @functionCancel="isActive({})"
      />
      <PauseActivateModal
        :isPausing="isPauseAvtivating"
        :dialog="isPauseAvtivate"
        :dialogItem="dialogItem"
        @functionAgree="handlePauseActivate($event)"
        @functionCancel="pauseAvtivate({})"
      />
      <ShowInMobileTable :campaignData="campains" class="showMobile" />
    </v-card>
  </div>
</template>
<script>
import { campaignService } from "@/services/campaign";
import activeIcon from "../../assets/icon/active-status-icon.svg";
import sortIcons from "../../assets/icon/sort-icons.svg";
import deleteButton from "../../assets/icon/delete-button.svg";
import iconColumnsIcon from "../../assets/icon/icon-columns.svg";

import {
  CAMPAIGN_STATUSES,
  CURRENCIES,
  numberWithSpaces,
  PERIODS,
} from "@/utils";
import { debounce } from "lodash";
import { authService } from "@/services/auth";

export default {
  name: "MyCampaigns",
  components: {
    ColumnsComponent: () => import("@/components/Account/ColumnsComponent"),
    CoveredLoader: () => import("@/components/CoveredLoader"),
    PauseActivateModal: () => import("@/components/dialogs/PauseActivateModal"),
    ShowInMobileTable: () => import("@/components/Account/ShowInMobileTable"),
    Pagination: () => import("@/components/Pagination"),
    DeleteModal: () => import("@/components/dialogs/DeleteModal"),
  },
  data: () => ({
    filterType: 0,
    iconColumnsIcon,
    menu1: false,
    menu2: false,
    date: false,
    menu: false,
    startDate: "",
    endDate: "",
    options: {
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
    ],
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    loading: false,
    dialog: false,
    isHaveError: false,
    isGettingCampaigns: false,
    isSearchInput: false,
    isdelete: false,
    isPauseAvtivate: false,
    isPauseAvtivating: false,
    warningText: false,
    dialogItem: {},
    limitPage: 8,
    currentPage: 1,
    headers: [
      {
        text: "my_campaigns_page.table_header_campaign",
        isSorting: false,
        value: "name",
      },
      {
        text: "my_campaigns_page.table_header_budget",
        isSorting: false,
        value: "budget",
      },
      {
        text: "my_campaigns_page.table_header_impressions",
        isSorting: false,
        value: "impressions",
      },
      {
        text: "my_campaigns_page.table_header_clicks",
        isSorting: false,
        value: "clicks",
      },
      {
        text: "my_campaigns_page.table_header_CTR",
        isSorting: false,
        value: "ctr",
      },
      {
        text: "my_campaigns_page.table_header_CPC",
        isSorting: false,
        value: "cpc",
      },
      {
        text: "my_campaigns_page.table_header_cost",
        value: "cost",
        isSorting: false,
      },
      {
        text: "my_campaigns_page.table_header_status",
        isSorting: false,
        value: "status",
      },
      {
        text: "my_campaigns_page.table_header_actions",
        value: "actions",
      },
    ],
    additionalHeaders: [
      {
        text: "my_campaigns_page.table_header_conversions",
        value: "conversions",
        isSorting: false,
      },
      {
        text: "my_campaigns_page.table_header_cost_per_conversions",
        value: "costCon",
        isSorting: false,
      },
      {
        text: "my_campaigns_page.table_header_conversions_rate",
        value: "conRate",
        isSorting: false,
      },
      {
        text: "my_campaigns_page.table_header_conversions_value",
        value: "conValue",
        isSorting: false,
      },
      {
        text: "my_campaigns_page.table_header_conversions_value_cost",
        value: "conValueCost",
        isSorting: false,
      },
    ],
    itemCurrency: {
      0: "US$",
    },
    sort: "ASC",
    campains: [],
    conDesserts: [],
    activeIcon,
    sortIcons,
    deleteButton,
  }),
  async created() {
    try {
      this.loading = true;
      await this.getCampaigns();
      const { data } = await authService.getUserInfo();
      this.userLinkedAccounts = data?.googleAdsAccounts;
      this.loading = false;
    } catch (err) {
      const errCode = err.response.statusText;
      this.$notify({
        group: "foo",
        type: "error",
        title: errCode,
      });
    }
  },
  watch: {
    startDate() {
      if (this.endDate) {
        this.getCampaigns();
      }
    },
    endDate() {
      if (this.startDate) {
        this.getCampaigns();
      }
    },
  },
  methods: {
    isHaveItemHeader(val) {
      const findItem = this.headers.find((item) => item.value === val);
      return !!findItem;
    },
    clickApplyColumns(data) {
      let arr = [];
      if (data[0].checked) {
        const findItem = this.headers.find(
          (item) => item.value === "conversions"
        );
        if (!findItem) {
          arr.push(this.additionalHeaders[0]);
        }
      }
      if (data[1].checked) {
        const findItem = this.headers.find((item) => item.value === "costCon");
        if (!findItem) {
          arr.push(this.additionalHeaders[1]);
        }
      }
      if (data[2].checked) {
        const findItem = this.headers.find((item) => item.value === "conRate");
        if (!findItem) {
          arr.push(this.additionalHeaders[2]);
        }
      }
      if (data[3].checked) {
        const findItem = this.headers.find((item) => item.value === "conValue");
        if (!findItem) {
          arr.push(this.additionalHeaders[3]);
        }
      }
      if (data[4].checked) {
        const findItem = this.headers.find(
          (item) => item.value === "conValueCost"
        );
        if (!findItem) {
          arr.push(this.additionalHeaders[4]);
        }
      }
      const indexStatus = this.headers.findIndex(
        (item) => item.value === "status"
      );
      if (arr.length > 0) {
        arr.forEach((item, index) => {
          this.headers.splice(indexStatus + index, 0, item);
        });
      }
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getRoundedNumbers(num) {
      return Math.round(num * 100) / 100;
    },
    changeSearchText: debounce(async function (searchText) {
      if (!searchText) {
        await this.getCampaigns();
      }
      const filteredData = this.staticData.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      this.isGettingCampaigns = true;
      setTimeout(() => {
        this.conDesserts = filteredData;
        this.isGettingCampaigns = false;
      }, 500);
    }, 500),
    changeFilter(val) {
      if (val !== 13) {
        this.getCampaigns(val);
      }
    },
    setCampaign(response) {
      this.campaign = response?.data;
    },
    async getCampaigns(val) {
      this.isGettingCampaigns = true;
      const customerId = localStorage.getItem("customerId");
      if (!customerId) {
        this.isGettingCampaigns = false;
        return;
      }
      let payload = {};
      const payloadData = {
        customerId,
        predefined: true,
        period: val,
      };
      if (!val) {
        delete payloadData.predefined;
        delete payloadData.period;
      }
      if (this.filterType === 13) {
        console.log("this.startDate: ", new Date(this.startDate).getTime());
        payload = {
          customerId,
          predefined: false,
          fromDate: new Date(this.startDate).getTime(),
          toDate: new Date(this.endDate).getTime(),
        };
      } else {
        payload = payloadData;
      }
      try {
        const res = await campaignService.getByCustomerId(payload);
        this.campains = res?.data.map((item) => {
          const { budget, statistics } = item;
          return {
            name: item?.companyName,
            status: item.status,
            clicks: statistics?.clicks,
            impressions: statistics?.impressions,
            cost: `${CURRENCIES[budget?.currency]} ${this.getRoundedNumbers(
              statistics?.cost
            )}`,
            cpc: `${CURRENCIES[budget?.currency]} ${this.getRoundedNumbers(
              statistics?.cpc
            )}`,
            ctr: `${this.getRoundedNumbers(statistics?.ctr)}%`,
            budgetI: `${CURRENCIES[budget?.currency]} ${
              budget?.amout * 7
            }/${this.$t(PERIODS[budget.period])}`,
            conversions: statistics.conversions,
            costCon: `${CURRENCIES[budget?.currency]} ${this.getRoundedNumbers(
              statistics?.costCon
            )}`,
            conRate: `${this.getRoundedNumbers(statistics?.conRate)}%`,
            conValue: `${CURRENCIES[budget?.currency]} ${this.getRoundedNumbers(statistics?.conValue)}`,
            conValueCost: `${this.getRoundedNumbers(statistics?.conValueCost)}`,
            ...item,
          };
        });
        this.staticData = this.campains;
        this.currentPage = 1;
        this.conDesserts = this.campains.slice(0, this.limitPage);
      } catch (err) {
        const errTitle = err.response?.data?.title;
        console.log(errTitle);
      }
      this.isGettingCampaigns = false;
    },
    isHaveLinkedItems() {
      const having = this.userLinkedAccounts?.find((item) => item.linked);
      return !!having;
    },
    clickNewCampaignButton() {
      const customerId = localStorage.getItem("customerId");
      if (customerId && this.userLinkedAccounts?.length > 0) {
        if (!this.isHaveLinkedItems()) {
          this.$notify({
            group: "foo",
            type: "warning",
            title: this.$t("have_to_link"),
          });
          this.isHaveError = true;
          this.warningText = this.$t("have_to_link");
        } else {
          this.$router.push(`/add-advertising/advertising-goals`);
        }
      } else {
        if (customerId) {
          this.$router.push(`/add-advertising/advertising-goals`);
        } else {
          this.$notify({
            group: "foo",
            type: "warning",
            title: this.$t("have_to_select"),
          });
          this.warningText = this.$t("have_to_select");
        }
      }
    },
    handleClickDetails(e, val) {
      e.stopPropagation();
      this.$router.push(`my-campaigns-detail/${val}`);
    },
    async handleDelete(val) {
      this.isdelete = true;
      try {
        await campaignService.delete(val);
        this.$notify({
          group: "foo",
          title: this.$t("my_campaigns_page.successfully_deleted"),
          type: "success",
          timestamp: 2000,
        });
      } catch (err) {
        const errTitle = err.response?.data?.title;
        this.$notify({
          group: "foo",
          type: "error",
          title: errTitle,
        });
      }
      await this.getCampaigns();
      this.isdelete = false;
      this.dialog = false;
      this.currentPage = 1;
    },
    async handlePauseActivate(val) {
      this.isPauseAvtivating = true;
      try {
        const response = await campaignService.toggleStatus(val);
        this.setCampaign(response);
      } catch (err) {
        const errCode = err.response?.statusText || this.$t("something_wrong");
        this.$notify({
          group: "foo",
          type: "error",
          title: errCode || this.$t("something_wrong"),
        });
      }
      this.isPauseAvtivate = false;
      this.isPauseAvtivating = false;
      await this.getCampaigns();
    },
    handleClickSort(item) {
      if (item.text === "Actions") return;
      if (item.text === "Status") return;
      this.sort = this.sort === "ASC" ? "DESC" : "ASC";
      const sortKeyword = this.sort;
      if (item.value === "name" || item.value === "status") {
        this.conDesserts = this.conDesserts.sort(function (a, b) {
          if (a[item.value].toLowerCase() < b[item.value].toLowerCase())
            return sortKeyword === "ASC" ? -1 : 1;
          if (a[item.value].toLowerCase() > b[item.value].toLowerCase())
            return sortKeyword === "ASC" ? 1 : -1;
          return 0;
        });
      } else if (item.value === "budget") {
        this.conDesserts.sort(function (a, b) {
          const isHuger = a.budget.amout - b.budget.amout;
          return sortKeyword === "ASC" ? isHuger : -isHuger;
        });
      } else {
        this.conDesserts = this.conDesserts.sort(function (a, b) {
          const isHuger = a.statistics[item.value] - b.statistics[item.value];
          return sortKeyword === "ASC" ? isHuger : -isHuger;
        });
      }
      item.isSorting = true;
      this.headers.forEach((itemHeader) => {
        if (itemHeader.text !== item.text) {
          itemHeader.isSorting = false;
        }
      });
    },
    isActive(item) {
      this.dialog = !this.dialog;
      this.dialogItem = item;
    },
    pauseAvtivate(item) {
      this.isPauseAvtivate = !this.isPauseAvtivate;
      this.dialogItem = item;
    },
    handleUpdateCampaignList(val) {
      let index = (val - 1) * this.limitPage;
      this.conDesserts = this.campains.slice(index, index + this.limitPage);
    },
    changePage(page) {
      this.currentPage = page;
      this.handleUpdateCampaignList(this.currentPage);
    },
    nextPage() {
      if (this.currentPage < this.totalPage) this.currentPage += 1;
      this.handleUpdateCampaignList(this.currentPage);
    },
    prePage() {
      if (this.currentPage > 1) this.currentPage -= 1;
      this.handleUpdateCampaignList(this.currentPage);
    },
  },
  computed: {
    getBreakPoint() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    itemsSelects() {
      return [
        {
          value: 13,
          label: this.$t("details_page.custom_range"),
        },
        {
          value: 0,
          label: this.$t("details_page.filter_today"),
        },
        {
          value: 1,
          label: this.$t("details_page.filter_yesterday"),
        },
        {
          value: 2,
          label: this.$t("details_page.filter_last7days"),
        },
        {
          value: 3,
          label: this.$t("details_page.filter_last_business_week"),
        },
        {
          value: 4,
          label: this.$t("details_page.filter_this_month"),
        },
        {
          value: 5,
          label: this.$t("details_page.filter_last_month"),
        },
        {
          value: 6,
          label: this.$t("details_page.filter_last_14month"),
        },
        {
          value: 7,
          label: this.$t("details_page.filter_last_30month"),
        },
        {
          value: 8,
          label: this.$t("details_page.filter_last_this_week_Sun_Today"),
        },
        {
          value: 9,
          label: this.$t("details_page.filter_last_this_week_Mon_Today"),
        },
        {
          value: 10,
          label: this.$t("details_page.filter_last_this_week_Sun_Sat"),
        },
        {
          value: 11,
          label: this.$t("details_page.filter_last_this_week_Mon_Sun"),
        },
        {
          value: 12,
          label: this.$t("details_page.filter_all_time"),
        },
      ];
    },
    totalPage: function () {
      return Math.ceil(this.campains.length / this.limitPage);
    },
    numberWithSpaces() {
      return function (val) {
        return numberWithSpaces(val.toString());
      };
    },
    GETCAMPAIGN_STATUS() {
      return CAMPAIGN_STATUSES;
    },
  },
};
</script>

<style lang="scss" scoped>
.Active {
  background: rgba(0, 87, 255, 0.1) !important;
  span {
    color: #0057ff !important;
  }
}

.Paused {
  background: rgba(242, 153, 74, 0.1) !important;
  span {
    color: #f2994a !important;
  }
}
.campaign-name-title {
  cursor: pointer !important;
  span:hover {
    text-decoration: underline !important;
  }
}

.showMobile {
  display: none;
  @media screen and (max-width: 800px) {
    display: block;
  }
}

.my-campaigns-card {
  box-shadow: none !important;
  border-radius: 12px !important;
  padding: 30px !important;
  @media screen and (max-width: 600px) {
    padding: 20px 25px !important;
  }
  @media screen and (max-width: 450px) {
    padding: 20px !important;
  }

  .create-btn {
    border-radius: 16px;
    font-weight: bold;

    @media screen and (max-width: 600px) {
      width: 48px !important;
      height: 48px !important;
    }

    // span {
    //   @media screen and (max-width: 600px) {
    //     display: none;
    //   }
    // }

    .icon {
      @media screen and (max-width: 600px) {
        font-size: 30px;
      }
    }
  }

  .table {
    box-shadow: none !important;
    @media screen and (max-width: 800px) {
      display: none;
    }

    thead {
      tr td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }

  .chip {
    height: 32px;
    border-radius: 10px;
    text-transform: uppercase;
  }

  .filter-div {
    .v-input__control {
      width: 100px !important;
    }
  }
}

.tbody {
  cursor: text !important;
  p {
    color: gray !important;
    font-size: 14px !important;
  }
}

.table-row {
  height: 80px !important;
  &:hover {
    background-color: #f6f9fc !important;
  }

  td {
    border: none !important;
  }
}

.tbody:hover {
  background-color: #f6f9fc !important;
}

.select {
  font-size: 14px;
  width: 100%;
  border-radius: 12px;
  font-weight: 500;
}

.create-btn-mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .fd-col {
    flex-direction: column;
  }
  .width-100 {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .fd-col-calendar {
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .create-btn {
    display: none;
  }
  .create-btn-mobile {
    display: block;
  }
  .button {
    border-radius: 50px !important;
    padding: 0 !important;
    min-width: 48px !important;
    height: 48px !important;
  }
  .ds-none {
    display: none !important;
  }
}

.thead tr td:nth-child(1) {
  padding-right: 100px;
}

.thead tr td:nth-child(2) {
  padding-right: 70px;
}

.columns-div {
  cursor: pointer;
}
</style>
